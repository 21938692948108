.boxcenter {
    display: flex;
    justify-content: center;
}

.prodpayrollimg {
    height: 200px;
    width: 200px;

}

.prodpayrollimg:hover {
    transition: all 0.5s;
    height: 210px;
    width: 210px;

}

.cardnew {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    font-size: 20px;
}

.cardCName {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50%;
    font-size: 17px;
}

.card {

    border-radius: 10px;
    padding: 18px;
    height: 200px;
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
    transition: all 0.2s;
    background-color: white;
    cursor: pointer;
}

.payschedulListcontainer {
    background-color: white;
    border-radius: 10;
    margin: 10px;
    box-shadow: 1px 1px 1px 1px gray;
}

.card:hover {
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.4);
    transform: scale(1.01);
}

.card__link,
.card__exit,
.card__icon {
    position: relative;
    text-decoration: none;
    color: rgba(72, 72, 78, 0.9);
}

.card__link::after {
    position: absolute;
    top: 25px;
    left: 0;
    content: "";
    width: 0%;
    height: 3px;
    background-color: rgba(37, 0, 220, 0.6);
    transition: all 0.5s;
}

.card__link:hover::after {
    width: 100%;
}

.card__exit {
    grid-row: 1/2;
    justify-self: end;
    background-color: antiquewhite;
}

.card__icon {
    grid-row: 1/3;
    background-color: aquamarine;
}

.card__title {
    grid-row: 3/4;
    font-weight: 400;
    color: #000000;
    background-color: blanchedalmond;
}

.card__apply {
    grid-row: 4/5;
    align-self: center;
}

/* CARD BACKGROUNDS */

.card-1 {
    background: "white";
}


/* RESPONSIVE */

@media (max-width: 1600px) {
    .cards {
        justify-content: center;
    }
}